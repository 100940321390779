.certificacoes-premios {
    .texto {
        margin-bottom: 30px;
    }
}
.download-certificacoes-wrapper {
    margin-bottom: 30px;
    .title {
        margin-bottom: 15px;
        h3 {
            display: inline-block;
            font-size: 16px;
            color: $orange-contraven;
            text-transform: uppercase;
            margin: 0px;
            margin-left: 8px;
        }
    }
    .download-boxes {
        margin-bottom: 30px;
        a {
            .download-bg {
                margin: 10px 0;
                padding: 12px 0;
                width: 100%;
                background-size: cover;
                background-image: url('../images/download-bg.jpg');
                background-repeat: no-repeat;
                .name {
                    font-size: 14px;
                    color: #FFF;
                    text-transform: uppercase;
                    margin-left: 20px;
                    font-weight: 500;

                }
                i {
                    float: right;
                    margin-right: 20px;
                    color: #FFF;
                    font-weight: 500;
                    font-size: 24px;
                }
            }
            &:hover {
                text-decoration: none !important;
            }
        }
    }
}

.premios {
    border-top: 1px solid #666;
    .premio-wrapper {
        margin-top: 30px;
        text-align: center;
        .ano {
            font-weight: lighter;
            color: $orange-contraven;
            font-size: 26px;
        }
        .info {
            font-weight: normal;
            color: $grey;
            font-size: 13px;
        }
    }
}