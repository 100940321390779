.slider-wrapper{
    position : relative;
    .imgs-slider, .home-slider {
        img {
            width: 100%;
        }
    }
    .owl-controls{
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        .owl-pagination{
            display: inline-block;
            .owl-page{
                height: 20px;
                width: 20px;
                border-radius: 100%;
                border: 3px solid white;
                background-color: white;
                @include transition();
                display: inline-block;
                margin: 0px 10px;
                &.active{
                    background-color: $orange-contraven;
                    @include transition();
                }
            }
        }
        .owl-buttons{
            position: absolute;
            width: 100%;
            .owl-prev{
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                font-size: 35px;
                color: #FFF;
            }
            .owl-next{
                position: absolute;
                right: 0;
                top: 0;
                display: inline-block;
                font-size: 35px;
                color: #FFF;
            }
        } 
    } 
    .slider-button-wrapper{
        position: absolute;
        top: 50%;
        height: 0px;
        @include prefix(transform, translateY(-50%), webkit ms moz o);
        left: 0;
        width: 100%;
        .arrow{
            display : inline-block;
            position : absolute;
            cursor: pointer;
            top : 0;
            padding : 10px;
            i{
                font-size: 40px;
                color : white;
            }
            &.prev{

                left: 0;
            }
            &.next{

                right: 0;
            }
        }
    }
}

.imgs-slider {
    .owl-controls {
        bottom: 35px;
    }
    .owl-pagination{
        display: inline-block;
        .owl-page{
            height: 16px !important;
            width: 16px !important;
            border-radius: 100%;
            border: 3px solid white;
            background-color: white;
            @include transition();
            display: inline-block;
            margin: 0px 5px !important;
            &.active{
                background-color: transparent !important;
                @include transition();
            }
        }
    }
}

.desktop-banner {
    .home-slider {
        .item {
            .item-wrapper {
                position: relative;
                .info-content {
                    position: absolute;
                    width: 100%;
                    top: 80px;
                    left: 0px;
                    .container {
                        position: relative;
                        background-color: rgba(white, 0.8);
                        .title {
                            max-width: 460px;
                            h1 {
                                font-weight: 100;
                                color: $grey;
                                display: block;
                                font-size: 40px;
                                text-transform: uppercase;
                            }
                        }
                        .frase-link {
                            padding-top: 70px;
                            padding-bottom: 15px;
                            display: block;
                            .frase {
                                color: $orange-contraven;
                                text-transform: uppercase;
                            }
                        }
                        .setas-wrapper {
                            position: absolute;
                            top: 26px;
                            right: 20px;
                            .prev {
                                margin-right: 5px;
                                i {
                                    padding-right: 2px;
                                }
                            }
                            .next {
                                margin-left: 5px;
                                i {
                                    padding-left: 2px;
                                }
                            }
                            .arrow {
                                display: inline-block;
                                i {
                                    background-color: $orange-contraven;
                                    color: #FFF;
                                    font-size: 12px;
                                    height: 25px;
                                    width: 25px;
                                    text-align: center;
                                    vertical-align: middle;
                                    padding-top: 7px;
                                    cursor: pointer;
                                    border-radius: 22px;
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-banner {
    .home-slider {
        .item {
            .item-wrapper {
                position: relative;
                .info-content {
                    position: absolute;
                    width: 100%;
                    top: 80px;
                    left: 0px;
                    .container {
                        position: relative;
                        .col-xs-12 {
                            background-color: rgba(white, 0.8);
                            .title {
                                max-width: 90%;
                                h1 {
                                    font-weight: 100;
                                    color: $grey;
                                    display: block;
                                    font-size: 28px;
                                    text-transform: uppercase;
                                }
                            }
                            .frase-link {
                                padding-top: 15px;
                                padding-bottom: 15px;
                                display: block;
                                .frase {
                                    color: $orange-contraven;
                                    font-size: 13px;
                                    text-transform: uppercase;
                                }
                            }
                            .setas-wrapper {
                                position: absolute;
                                top: 26px;
                                right: 20px;
                                .prev {
                                    margin-right: 5px;
                                    i {
                                        padding-right: 2px;
                                    }
                                }
                                .next {
                                    margin-left: 5px;
                                    i {
                                        padding-left: 2px;
                                    }
                                }
                                .arrow {
                                    display: inline-block;
                                    i {
                                        background-color: $orange-contraven;
                                        color: #FFF;
                                        font-size: 12px;
                                        height: 25px;
                                        width: 25px;
                                        text-align: center;
                                        vertical-align: middle;
                                        padding-top: 6px;
                                        cursor: pointer;
                                        border-radius: 22px;
                                        border: none;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

.home-slider {
    .icons-elements-wrapper {
        padding: 10px 0px;
        background-color: $orange-contraven;
/*        margin-top: 30px;*/
        .icon-element {
/*            height: 132px;*/
/*            margin-bottom: 10px;*/
            svg {
                margin: 0px auto;
                display: block;
                vertical-align: super;
                text-align: center;
            }
            .frase {
                margin-left: 10px;
                color: #FFF;
                text-align: center;
                display: block;
                .nr {
                    font-weight: bold;
                    font-size: 30px;
                }
                .material {
                    font-size: 15px;
                }
            }
        }
    }
}

.bg-orange {
    background-color: $orange-contraven;
}

.voltar-seta {
    padding-left: 0px !important;
}