header{
    background-color: $header-bg-color;
    color: $header-color;
    font-size:16px;
    border-bottom: 3px solid #F7941E;
    padding:35px 0;
    position: relative;
    .logo {
        display: inline-block;
    }
}


.container-fluid {
    padding: 0px;
    .cookies-wrapper {
        padding: 20px;
        background-color: #FFF;
        p {
            display: inline-block;
            color: $grey;
        }
        .aceitar-cookies {
            padding: 3px 15px 5px 15px;
            color: #FFF;
            display: inline-block;
            margin-left: 30px;
            border-radius: 20px;
            box-shadow: none;
            border: none;
            background-color: $grey;
        }
    }
}