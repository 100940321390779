
a.btnblabla {
    @include bg-gradient-t2b(black, white);
    color: $secundary-color;
    padding: 15px;
}

a.btnblabla2 {
    @include bg-gradient-t2b(black, red);
    color: $secundary-color;
    padding: 15px;
}



.fadein-btn {
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
}

.fadeout-btn {
    opacity: 1;
    &:hover {
        opacity: 0.75;
    }
}

.darken-btn:hover {
    background: #0396AD;
}

.changecolor-btn:hover {
    background: #A5CD3A;
}

.insetborder-btn:hover {
    box-shadow: inset 0 0 0 5px #A5CD3A;
}

.shrink-btn:hover {
    -webkit-transform: scale(0.8);
    -o-transform: scale(1.1);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.grow-btn:hover {
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
    backface-visibility: hidden;
}

.button-btn {
    border: 0;
    background: #0087cc;
    border-radius: 4px;
    box-shadow: 0 5px 0 #006599;
    color: #fff;
    cursor: pointer;
    font: inherit;
    outline: 0;
    transition: all .1s linear;
}

.button-btn:active {
    box-shadow: 0 2px 0 #006599;
    transform: translateY(3px);
}

.sliding-btn {
    color: #FFF;
    background: #5DBBD1;
    margin: 25px;
    text-align: center;
    box-shadow: inset 0 0 0 0 #A5CD3A;
}
.sliding-btn:hover {
    box-shadow: inset 0 100px 0 0 #A5CD3A;
}

.button-default {
    cursor: pointer;
    background: #5DBBD1;
    color: #fff;
    font-family: Sans-serif;
    font-size: 20px;
    height: 60px;
    width: 150px;
    line-height: 60px;
    margin: 25px 25px;
    text-align: center;
    border: 0;
    @include transition;
}

.btn-video {
    position: absolute;
    top: 35%;
    left: 45%;
    i {
        height: 60px;
        width: 60px;
        background-color: $orange-contraven;
        text-align: center;
        vertical-align: top;
        color: #FFF;
        font-size: 44px;
        border-radius: 40px;
        padding-top: 8px;
        padding-left: 5px;
    }
}


