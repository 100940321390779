.downloads {
    .title {
        margin-bottom: 15px;
        h3 {
            display: inline-block;
            font-size: 16px;
            color: $orange-contraven;
            text-transform: uppercase;
            margin: 0px;
            margin-left: 8px;
        }
    }
    .downloads-wrapper {
        .destaque {
            .download-box {
                position: relative;
                .img-wrapper {
                    img {
                        width: 100%;
                    }
                }
                .opacity-background {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background-color: rgba($orange-contraven, 0.8);
                    @include transition;
                    width: 45%;
                    .info-wrapper {
                        padding: 30px;
                        .title {
                            color: #FFF;
                            margin: 0px;
                            height: 50px;
                            max-height: 50px;
                            overflow: hidden;
                            h4 {
                                font-size: 22px;
                                margin: 0px;
                                font-weight: 200;

                            }
                        }
                        .link {
                            i {
                                display: inline-block;
                                border-radius: 10px;
                                background-color: #FFF;
                                color: $orange-contraven;
                                font-size: 7px;
                                height: 10px;
                                width: 10px;
                                text-align: center;
                                vertical-align: middle;
                                padding-top: 1px;
                                padding-left: 1px;
                                margin-top: -2px;
                            }
                            .text {
                                text-transform: uppercase;
                                font-weight: 500;
                                font-size: 12px;
                                color: #FFF;
                                margin-left: 1px;
                            }
                        }
                    }
                    &:hover {
                        @include transition;
                        width: 100%;
                    }
                }
            }
        }

        .sem-destaque {
            margin-bottom: 37px;
            .download-box {
                position: relative;
                .img-wrapper {
                    img {
                        width: 100%;
                    }
                }
                .opacity-background {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background-color: rgba($orange-contraven, 0.8);
                    @include transition;
                    height: 100%;
                    width: 45%;

                    .info-wrapper {
                        margin-top: 20px;
                        margin-left: 30px;
                        .title {
                            color: #FFF;
                            margin: 0px;
                            height: 50px;
                            max-height: 50px;
                            overflow: hidden;
                            h4 {
                                font-size: 22px;
                                margin: 0px;
                                font-weight: 200;
                            }
                        }
                        .link {
                            i {
                                display: inline-block;
                                border-radius: 10px;
                                background-color: #FFF;
                                color: $orange-contraven;
                                font-size: 7px;
                                height: 10px;
                                width: 10px;
                                text-align: center;
                                vertical-align: middle;
                                padding-top: 1px;
                                padding-left: 1px;
                                margin-top: -2px;
                            }
                            .text {
                                text-transform: uppercase;
                                font-weight: 500;
                                font-size: 12px;
                                color: #FFF;
                                margin-left: 1px;
                            }
                        }
                    }
                    &:hover {
                        @include transition;
                        width: 100%;
                    }
                }
            }
        }
    }
}
