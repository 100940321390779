body {
    background-color:white;
    color: $secundary-color;
    font-family: 'Raleway', sans-serif;
    overflow-x: hidden;
}

section {
    margin-top: 30px;
    margin-bottom: 30px;
}

.active {
    a {
        color: $orange-contraven;
    }
}

.display-none {
    display: none;
}

.texto {
    font-size: 13px;
    color: $grey;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-70 {
    margin-top: 70px;
}

.border-bottom {
    border-bottom:1px solid $grey;
}

input[type=search]{
    border-radius:10px;
    border: 0px;
    padding-left: 10px;
    color: black;
    width: 455px;
}

/* all */
::-webkit-input-placeholder { color:black; font-style:italic; }
::-moz-placeholder { color:black; font-style:italic; }
:-ms-input-placeholder { color:black; font-style:italic; }
input:-moz-placeholder { color:black; font-style:italic; }

.position-relative {
    position: relative;
}

.red {
    color: #d44950;
}

.inline-block {
    display: inline-block;
}

.erro {
    margin: 0px;
    .container-fluid {
        position: relative;
        padding: 0px;
        .img-wrapper {
            img {
                width: 100%; 
            }
        }
        .error-message-wrapper {
            position: absolute;
            top:15%;
            padding-top: 10px;
            padding-bottom: 30px;
            width: 100%;
            background-color: rgba(256, 256, 256, 0.5);
            .nr {
                font-size: 60px;
            }
            .mensagem {
                font-size: 20px;
            }
            .nr, .mensagem {
                color: $red-contraven;
                text-align: center;
            }
        }
    }
}

textarea, input[type="text"], input[type="password"], 
input[type="datetime"], input[type="datetime-local"], 
input[type="date"], input[type="month"], input[type="time"], 
input[type="week"], input[type="number"], input[type="email"], 
input[type="url"], input[type="tel"], 
input[type="color"] { 
    font-size: 16px; 
}

.form-control {
    font-size: 16px;
}