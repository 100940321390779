/* Google Maps */
.contacts-maps-wrapper {
    .google-maps-wrapper {
        .google-maps {
            position: relative; 
            .mapa1 {
                position: relative !important; 
                z-index: 1;
            }
            .mapa {
                position: absolute;
                top: 0px;
                left: 0px;
                width:100%;
                height:454px;
            }
        }
    }
    .countries-wrapper {
        .position-countries {
            position: absolute;
            transform: translate(-50%, 0%);
            top: 25%;
            left: 50%;
            z-index: 2;
            .opacity-background {
                background-color: rgba(256, 256, 256, 0.9);
                .select-country-wrapper {
                    padding: 10px;
                    .countries-wrapper {
                        li {
                            text-transform: uppercase;
                            font-weight: 100;
                            font-size: 39px;
                            color: $grey;
                            margin-bottom: 0px;
                            cursor: pointer;
                            line-height: 39px;
                            &:hover {
                                color: $orange-contraven;
                            }
                        }
                    }

                    .address-wrapper {
                        color: $orange-contraven;

                        .address {
                            font-size: 13px;
                        }
                        .contacts {
                            font-size: 13px;
                        }
                    }

                    .redes-sociais {
                        float: right;
                        text-align: right;
                        .fb {
                            margin-left: 10px;
                            display: inline-block;
                        }
                        .email, .fb {
                            display: inline-block;
                            i {
                                border-radius: 22px;
                                background-color: $orange-contraven;
                                color: white;
                                font-size: 12px;
                                height: 22px;
                                width: 22px;
                                text-align: center;
                                vertical-align: top;
                                padding-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.thankyou-message-wrapper {
    .help-block {
        color: $orange-contraven;
        position: absolute;
        top: 8px;
        left: 12px;
        background-color: white;
        height: 30px;
        text-transform: uppercase;
    }
}

.contactos {
    margin: 0px;
    .titles {
        h1 {
            font-weight: 100;
            font-size: 22px;
            color: $grey;
        }
        h2 {
            font-size: 12px;
            color: $orange-contraven;
            margin-top: 0px;
            margin-bottom: 20px;
            i {
                border: 0px;
                display: inline-block;
                border-radius: 10px;
                background-color: #F6921E;
                color: #FFF;
                font-size: 6px;
                height: 12px;
                width: 12px;
                text-align: center;
                vertical-align: middle;
                padding-top: 2.5px;
                padding-left: 2.5px;
                margin-top: -4px;
                margin-right: 2px;
            }
        }
    }
    .form-wrapper {
        form {
            .col-md-3 {
                padding-right: 0px;
            }
            .col-md-9 {
                padding-left: 0px;
            }
            .form-group {
                margin: 0px;
                input, textarea {
                    border-radius: 0px;
                    border: 1px solid $grey;
                    resize: none;
                }
                textarea {
                    border-left: none;
                    padding-top: 14px;
                    height: 134px;
                }
                input {
                    height: 44.5px;
                }
                #name, #email {
                    border-bottom: 0px;
                }
            }
            .message-wrapper {
                margin-bottom: 20px;
                position: relative;
                .btn-enviar-wrapper {
                    position: absolute;
                    bottom: 5px;
                    right: 10px;
                    .icon-btn-enviar {
                        border: 0px;
                        display: inline-block;
                        border-radius: 10px;
                        background-color: $orange-contraven;
                        color: #FFF;
                        font-size: 6px;
                        height: 12px;
                        width: 12px;
                        text-align: center;
                        vertical-align: middle;
                        padding-top: 2.5px;
                        padding-left: 4.5px;
                        margin-top: -6px;
                        margin-right: 6px;
                    }
                    .btn-enviar { 
                        padding: 0px;
                        border: 0px;
                        background-color: transparent;
                        text-transform: uppercase;
                        color: $orange-contraven;
                        font-size: 12px;
                    }
                }
            }
            ::-webkit-input-placeholder { color:$grey; font-size: 11px; font-family: 'Raleway'; font-weight: 500; font-style: normal !important; }
            ::-moz-placeholder { color:$grey; font-size: 11px; font-family: 'Raleway'; font-weight: 500; font-style: normal !important; }
            :-ms-input-placeholder { color:$grey; font-size: 11px; font-family: 'Raleway'; font-weight: 500; font-style: normal !important; }
            input:-moz-placeholder { color:$grey; font-size: 11px; font-family: 'Raleway'; font-weight: 500; font-style: normal !important; }
        }
    }
}

.active-address {
    color: $orange-contraven !important;
}

.error-messages-wrapper {
    span {
        color: $orange-contraven;
        text-transform: uppercase;
        font-size: 12px;
    }
}