ul {
    list-style-type: none;
    padding-left: 0px;
}

.areas {
    .areas-destaques {
        li {
            margin-top: 30px;
            a {
                .inner {
                    position: relative;
                    img {
                        width: 100%;
                    }
                    .opacity-background {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(255, 255, 255, 0.8);
                        @include transition;
                        width: 100%;
                        height: 50%;
                        .info-wrapper {
                            margin-left: 30px;
                            margin-top: 35px;
                            .title {
                                font-size: 17px;
                                font-weight: 200px;
                                color: $grey;
                                margin-bottom: 10px;
                                text-transform: uppercase;
                            }
                            .keywords {
                                display: none;
                                .li {
                                    opacity: 0;
                                }
                            }
                            .link {
                                font-size: 12px;
                                color: #F79420;
                                text-transform: uppercase;
                                i {
                                    border-radius: 10px;
                                    background-color: $orange-contraven;
                                    color: #FFF;
                                    font-size: 7px;
                                    height: 14px;
                                    width: 14px;
                                    text-align: center;
                                    vertical-align: top;
                                    padding-top: 4px;
                                    padding-left: 2px;
                                }
                                .text {
                                    color: $orange-contraven;
                                    margin-left: 3px;
                                }
                            }
                            .plus {
                                display: none;
                            }
                        }
                        &:hover {
                            @include transition;
                            background-color: rgba($orange-contraven, 0.8);
                            height: 100%;
                            .title {
                                color: #FFF;
                                font-size: 22px;
                                font-weight: bold;
                                margin-bottom: 0px;
                            }
                            .keywords {
                                display: block;
                                @include transition;
                                opacity: 1;
                                li {
                                    color: #FFF;
                                    margin-top: 0px;
                                    text-transform: uppercase;
                                    font-weight: 200;
                                    font-size: 22px;
                                    
                                    
                                }
                            }
                            .link {
                                display: none;
                            }
                            .plus {
                                display: block;
                                color: #FFF;
                                position: absolute;
                                right: 35px;
                                bottom: 25px;
                            }
                        }
                    }
                }
            }
        }
    }

    .areas-pequenas {
        li {
            margin-top: 30px;
            a {
                .inner {
                    position: relative;
                    img {
                        width: 100%;
                    }
                    .opacity-background {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(255, 255, 255, 0.8);
                        @include transition;
                        width: 100%;
                        height: 50%;
                        .info-wrapper {
                            margin-left: 30px;
                            margin-top: 35px;
                            .title {
                                font-size: 17px;
                                font-weight: 200px;
                                color: $grey;
                                margin-bottom: 10px;
                                text-transform: uppercase;
                            }
                            .frase {
                                display: none;
                            }
                            .keywords {
                                display: none;
                            }
                            .link {
                                font-size: 12px;
                                color: #F79420;
                                text-transform: uppercase;

                                i {
                                    border-radius: 10px;
                                    background-color: $orange-contraven;
                                    color: #FFF;
                                    font-size: 7px;
                                    height: 14px;
                                    width: 14px;
                                    text-align: center;
                                    vertical-align: top;
                                    padding-top: 4px;
                                    padding-left: 2px;
                                }
                                .text {
                                    color: $orange-contraven;
                                    margin-left: 3px;
                                }
                            }
                            .plus {
                                display: none;
                            }
                        }
                        &:hover {
                            @include transition;
                            background-color: rgba($orange-contraven, 0.8);
                            height: 100%;
                            .title {
                                display: none;
                            }
                            .frase {
                                display: block;
                                color: #FFF;
                                margin-top: 0px;
                                text-transform: uppercase;
                                font-weight: 200;
                                font-size: 22px;
                            }
                            .link {
                                display: none;
                            }
                            .plus {
                                display: block;
                                color: #FFF;
                                position: absolute;
                                right: 35px;
                                bottom: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}


