.grupo-contraven {
    .title {
        margin-bottom: 15px;
        i {
            display: inline-block;
            border-radius: 10px;
            background-color: $orange-contraven;
            color: #FFF;
            font-size: 7px;
            height: 14px;
            width: 14px;
            text-align: center;
            vertical-align: top;
            padding-top: 4px;
            padding-left: 2px;
            margin-top: 2px;
        }
        h3 {
            display: inline-block;
            font-size: 16px;
            color: $orange-contraven;
            text-transform: uppercase;
            margin: 0px;
            margin-left: 8px;
        }
    }
    .col-slider {
        margin-bottom: 30px;
    }
    .team-wrapper {
        .box {
            z-index: 2;
            margin-bottom: 20px;
            text-align: center;
            .img-wrapper {
                position: relative;
                z-index: 3;
                img {
                    border: 18px solid $orange-contraven;
                }
            }
            .info-wrapper {
                position: relative;
                margin-top: 10px;
                margin-bottom: 10px;
                color: $grey;
                .name {
                    height: 40px;
                    max-height: 40px;
                    overflow: hidden;
                }
                .job {
                    height: 40px;
                    max-height: 40px;
                    overflow: hidden;
                }
            }
        }
    }
}

/* fluid 5 columns */
/*.grid-sizer,
.box { width: 20%; }*/
.box {
    /*    height: 240px;
        max-height: 240px;
        overflow: hidden;*/
}
.margin-top-boxes {
    margin-top: 70px;
}

.box-info-wrapper {
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
    top: 50%;
    left: 50%;
    .info {
        background-color: $orange-contraven;
        height: 400px;
        width: 260px;
        color: #FFF;
        padding-top: 85px;
        .name {
            font-weight: bold;
            font-size: 15px;
            height: 40px;
            max-height: 40px;
            overflow: hidden;
        }
        .job {
            font-size: 15px;
            padding-bottom: 10px;
            height: 40px;
            max-height: 40px;
            overflow: hidden;
        }
        .text {
            font-size: 13px;
            padding-left: 15px;
            padding-right: 15px;
            height: 204px;
            max-height: 204px;
            overflow: hidden;
        }
        .close-btn {
            padding-top: 9px;
            cursor: pointer;
        }
    }
}

.z-index-opacity-down {
    opacity: 0.5;
    z-index: 1 !important;
}

.img-circle {
    background-color: white;
    z-index: 10;
    position: relative;
    cursor: pointer;
}

.clear-both {
    clear: both;
}