//cores
$primary-color:#5DBBD1;
$orange-contraven: #F6921E;
$red-contraven: #E7232A;
$secundary-color: #AAEFFF;
$background-color: #000;
$header-bg-color: #E7232A;
$header-color: #fff;
$sidebar-color: #3a3e64;
$footer-bg: #E5252B;
$grey: #58595B;
