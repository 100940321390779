.resultados-pesquisa {
    .big-headings {
        margin-bottom: 30px;
        h1 {
            color: #58585B;
            font-weight: 100;
            font-size: 40px;
            margin-bottom: 0px;
        }
        h2 {
            color: $orange-contraven;
            font-size: 13px;
            font-weight: normal;
            i {
                margin-top: 0px;
                margin-right: 1px;
            }
        }
    }
    .resultados-wrapper {
        margin-top: 30px;
        counter-reset:li;
        li {
            list-style-type:none;
            counter-increment:li;
            position:relative;
            color: #58585B;
            &:before {
                content:counter(li, decimal-leading-zero) ".";
                position:absolute;
                left: -25px;
                width: 3em;
                text-align: left;
                color: $orange-contraven;
                display: block;
                border-bottom: 1px solid #58585B;
                padding-bottom: 10px;
                padding-top: 10px;
            }

            a {
                color: #58585B;
                display: block;
                border-bottom: 1px solid #58585B;
                padding-bottom: 10px;
                padding-top: 10px;
                outline: 0;
                i {
                    float: right;
                    margin-right: 15px;
                    color: $orange-contraven;
                    display: inline-block;
                    margin-top: 5px;
                }
                &:hover {
                    i {
                        @include transform;
                    }
                    .resultado {
                        text-decoration: underline !important;
                        color: $orange-contraven;
                    }
                }
                .resultado {
                    display: inline-block;
                }
            }
        }
    }
}

