nav{
    padding-top: 15px;
    ul{
        list-style: none;
        margin:0;
        padding:0;
        li{
            display: inline-block;
            padding:0 5px;
            text-align: center;
            a{
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
                color: #FFF;
                &:hover, &:focus{
                    color: $orange-contraven;
                    text-decoration: none;
                    @include transition;
                }
            }
        }
    }
}
.menu-mobile {
    padding-top: 0px;
    .menu-body {
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba($orange-contraven, 0.8);
        position: absolute;
        padding-top: 40px;
        li {
            display: inline-block;
            width: 100%;
            a {
                font-size: 28px;
                font-weight: 300;
                color: #FFF;
            }
        }
        .redes-sociais {
            margin-top: 85px;
            .fb {
                margin-left: 10px;
            }
            .email, .fb {
                display: inline-block;
                i {
                    border-radius: 22px;
                    background-color: $red-contraven;
                    color: $orange-contraven;
                    font-size: 17px;
                    height: 40px;
                    width: 40px;
                    text-align: center;
                    vertical-align: top;
                    padding-top: 10px;
                }
            }
        }
        .active {
            a {
                color: $red-contraven !important;
            }
        }
    }
}

.menu-desktop, .search-wrapper {
    display: inline-block;
}

.search-wrapper {
    border: none;
    input, i {
        display: inline-block;
    }
    i {
        border-radius: 22px;
        background-color: #FFF;
        color: #BBBDC0;
        font-size: 11px;
        height: 25px;
        width: 25px;
        text-align: center;
        vertical-align: top;
        padding-top: 7px;
        cursor: pointer;
        @include transition1;
        &:hover {
            background-color: $orange-contraven;
            color: #FFF;
            @include transform;
            @include transition1;
        }
    }
}
.search-position {
    position: absolute;
    right: 15px;
    .search-input-wrapper {
        position: relative;
        .btn-search {
            position: absolute;
            top: 5px;
            right: 5px;
            border-radius: 22px;
            border: none;
            background-color: $orange-contraven;
            color: #FFF;
            font-size: 8px;
            height: 15px;
            width: 15px;
            text-align: center;
            vertical-align: middle;
            padding-top: 1.2px;
            cursor: pointer;
        }
    }
}


.padding-top-15 {
    padding-top: 15px;
}

/* Icon 1 */

#nav-icon3
{
    width: 40px;
    height: 25px;
    position: relative;
    margin: 5px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon3 span {
    display: block;
    position: absolute;
    height: 4px;
    width: 70%;
    background: $orange-contraven;
    border-radius: 0px;
    opacity: 1;
    left: 6px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}


/* Icon 3 */

#nav-icon3 span:nth-child(1) {
    top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
    top: 10px;
}

#nav-icon3 span:nth-child(4) {
    top: 20px;
}

#nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.menu-trigger {
    background-color: #FFF;
    padding: 2px;
}

.overflow-hidden-y {
    overflow-y: hidden !important;
}

.overflow-hidden-x {
    overflow-x: hidden !important;
}

.padding-right-0 {
    padding-right: 0px;
}

.active-hover {
    background-color: $orange-contraven !important;
    color: #FFF !important;
    @include transform;
}