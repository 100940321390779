@mixin transition(){
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

@mixin transition1(){
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

@mixin transform(){
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}


@mixin bg-gradient-t2b($start-colour, $end-colour) {
    background-color: $start-colour;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start-colour), to($end-colour));
    background-image: -webkit-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:    -moz-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:     -ms-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:      -o-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:         linear-gradient(to bottom, $start-colour, $end-colour);
    filter:            progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}');
}



@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
 
  // Output standard non-prefixed declaration
  #{$property}: $value;
}

//Como usar ? -> @include prefix(transform, rotate(45deg), webkit ms);
// Exporta : -webkit-transform: rotate(45deg);
  //         -ms-transform: rotate(45deg);
  //         transform: rotate(45deg);