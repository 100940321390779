footer{
    transition: all ease 0.3s;
    font-size:11px;
    color: #666;
    .border-top {
        border-top:1px solid $grey;
    }
    nav {
        padding-top: 10px;
        ul li {
            a {
                font-size: 11px;
                text-transform: uppercase;
                color: $orange-contraven;
                font-weight: 500;
                &:hover {
                    color: $red-contraven;
                }
            }
        }
    }
    .active {
        a {
            color: $red-contraven !important;
        }
    }

    .contraven-copyright {
        padding-top: 10px;
    }
}


/* Sticky Footer */
* {
    margin: 0;
}

html, body {
    height: 100%;
}
.wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -55px;
}
.footer, .push {
    height: 55px;
}
/* Sticky Footer */


.buttons-wrapper {
    position: fixed;
    z-index: 4;
    bottom: 15px;
    right: 17px;
    overflow: visible;
    text-align: center;
    cursor: pointer;
    .arrow{
        padding: 10px;
        padding-right: 12px;
        padding-left: 12px;
        width: 100%;
        i {
            font-size: 18px;
            background-color: $orange-contraven;
            height: 40px;
            width: 40px;
            text-align: center;
            vertical-align: middle;
            color: #FFF;
            border-radius: 40px;
            padding-top: 7px;
        }
    }
    span{
        font-size: 10px;
        width: 100%;
        text-align: center;
        display: block;
        margin-top: 5px;

    }
    a{
        display: block;
        text-decoration: none;
    }
}