
/* Large Devices, Wide Screens */
@media (min-width : 1200px){

}


@media (max-width : 993px){
    .margin--top70 {
        margin-top: -70px !important;
    }
}

@media (min-width : 1200px) and (max-width : 1400px) { 
    .orange-icons-wrapper {
        .desktop-banner .home-slider .item .item-wrapper .info-content .container .title h1 {
            font-size: 32px;
        }
        .desktop-banner .home-slider .item .item-wrapper .info-content {
            top: 20px !important;
        }
        .desktop-banner .home-slider .item .item-wrapper .info-content .container .title {
            height: 90px;
            max-height: 90px;
            overflow: hidden;
        }
    }
    .home-slider .icons-elements-wrapper .icon-element .frase .nr {
        font-size: 24px;
    }
}

@media (min-width : 992px) and (max-width : 1199px) {
    .orange-icons-wrapper {
        .desktop-banner .home-slider .item .item-wrapper .info-content .container .title h1 {
            font-size: 24px;
        }
        .desktop-banner .home-slider .item .item-wrapper .info-content {
            top: 10px !important;
        }
        .desktop-banner .home-slider .item .item-wrapper .info-content .container .title {
            height: 80px;
            max-height: 80px;
            overflow: hidden;
        }
    }
    .home-slider .icons-elements-wrapper .icon-element .frase .nr {
        font-size: 24px;
    }
    .contacts-maps-wrapper .countries-wrapper .position-countries {
        top: 30% !important
    }
    .redes-sociais .fb {
        margin-top: 10px;
    }
    .downloads .downloads-wrapper .sem-destaque .download-box .opacity-background .info-wrapper {
        margin-top: 10px;
    }
    .downloads .downloads-wrapper .destaque .download-box .opacity-background .info-wrapper {
        margin-top: 10px;
    }
    .big-heading {
        line-height: 30px;
        margin-bottom: 30px;
        h2 {
            font-size: 22px;
        }
    }
}

/* Medium Devices, Desktops */
@media (max-width : 992px){
    .clear-both {
        clear: none !important;
    }


    .big-heading {
        h2 {
            padding-right: 0px !important;
        }
    }
    .margin-top-boxes {
        margin-top: 0px !important;
    }
    .margin--top70 {
        margin-top: -70px !important;
    }
    div.box:nth-child(2) {
        margin-top: 70px !important;
    }
    /* fluid 2 columns */
    .grid-sizer,
    .box { width: 50%; }
    .big-heading {
        line-height: 30px;
        margin-bottom: 30px;
        h2 {
            font-size: 22px;
        }
    }
    .destaque {
        margin-bottom: 37px;
    }
    .areas-destaques, .areas-pequenas {
        margin-bottom: 0px;
        li {
            margin-top: 0px; 
            .col-xs-12, .col-xs-6 {
                padding: 0px;
                .inner {
                    margin-top: 10px;
                }
            }
        }
    }

    .padding-area-left {
        padding-left: 5px !important;
    }

    .padding-area-right {
        padding-right: 5px !important;
    }
    .downloads .downloads-wrapper .sem-destaque .download-box .opacity-background .info-wrapper {
        margin-top: 10px;
        margin-left: 20px;
    }
}

@media (max-width : 991px){
    .form-wrapper {
        form {
            .col-md-3 {
                padding-right: 15px !important;
            }
            .col-md-9 {
                padding-left: 15px !important;
            }
            .form-group {
                textarea {
                    border-left: 1px solid $grey !important;
                }
            }
        }
    }
}

@media (min-width : 993px) and (max-width : 1199px) {
    .areas .areas-pequenas li a .inner .opacity-background .info-wrapper {
        margin-left: 20px;
        margin-top: 15px;
    }
    .areas .areas-destaques li a .inner .opacity-background .info-wrapper {
        margin-left: 20px;
        margin-top: 15px;
    }
}

@media (min-width : 768px) and (max-width : 991px) {
    .opacity-background {
        width: 100% !important;
    }
    .contacts-maps-wrapper .countries-wrapper .position-countries {
        top: 25% !important;
    }
    .desktop-banner .home-slider .item .item-wrapper .info-content {
        top: 20px;
    }
    .orange-icons-wrapper {
        .desktop-banner .home-slider .item .item-wrapper .info-content .container .title h1 {
            font-size: 22px;
        }
        .desktop-banner .home-slider .item .item-wrapper .info-content {
            top: 5px !important;
        }
        .desktop-banner .home-slider .item .item-wrapper .info-content .container .title {
            height: 100px;
            max-height: 100px;
            overflow: hidden;
        }
    }
    .home-slider .icons-elements-wrapper .icon-element .frase .nr {
        font-size: 18px;
    }
    .desktop-banner .home-slider .item .item-wrapper .info-content .container .frase-link {
        padding-top: 0px;
    }
    .desktop-banner .home-slider .item .item-wrapper .info-content .container .title {
        height: 158px;
        max-height: 158px;
        overflow: hidden;
    }
}

/* Small Devices, Tablets */
@media (max-width : 768px){
    .container-fluid .cookies-wrapper {
        padding: 15px;
    }
    .address, .contacts {
        padding-left: 0px;
    }
    .position-countries {
        transform: translate(-50%, -50%) !important;
    }
    .icon-element {
        height: 132px;
    }
    .contacts-maps-wrapper .countries-wrapper .position-countries .opacity-background .select-country-wrapper .redes-sociais .email i, .contacts-maps-wrapper .countries-wrapper .position-countries .opacity-background .select-country-wrapper .redes-sociais .fb i {
        border-radius: 22px;
        background-color: #F6921E;
        color: white;
        font-size: 15px;
        height: 30px;
        width: 30px;
        text-align: center;
        vertical-align: top;
        padding-top: 7px;
    }
    .opacity-background {
        width: 280px;
    }
    .contacts-maps-wrapper .countries-wrapper .position-countries .opacity-background .select-country-wrapper {
        padding-left: 0px;
        padding-top: 10px;
    }
    .address-wrapper {
        height: 170px;
        max-height: 170px;
        overflow: hidden;
    }
    .contacts-maps-wrapper .countries-wrapper .position-countries {
        top: 43%;
    }

    .contacts-maps-wrapper .countries-wrapper .position-countries .opacity-background .select-country-wrapper .countries-wrapper li {
        font-size: 28px;
        line-height: 28px;
    }

    .mobile-banner .home-slider .item .item-wrapper .info-content .container .col-xs-12 .title {
        max-height: 166px;
        overflow: hidden;
    }

    .mobile-banner .home-slider .item .item-wrapper .info-content {
        position: absolute;
        width: 100%;
        top: 20px;
        left: 0px;
    }

    .contactos .form-wrapper form .message-wrapper .btn-enviar-wrapper .icon-btn-enviar {
        border: 0px;
        display: inline-block;
        border-radius: 10px;
        background-color: #F6921E;
        color: #FFF;
        font-size: 6px;
        height: 12px;
        width: 12px;
        text-align: center;
        vertical-align: middle;
        padding-top: 0px;
        padding-left: 4.5px;
        margin-top: -6px;
        margin-right: 6px;
    }
    .homepage {
        margin-top: 10px;
    }
    .border-top {
        border: none !important;
    }
    /* fluid 2 columns */
    .grid-sizer,
    .box { width: 50%; }
    .video-wrapper {
        margin-bottom: 10px;
    }
    .download-certificacoes-wrapper .download-boxes {
        margin-bottom: 0px; 
    }
    .video-column, .col-slider  {
        padding: 0px;
    }
    .big-heading {
        line-height: 25px;
    }
    .downloads .downloads-wrapper .destaque .download-box .opacity-background {
        width: 50%;
    }
    .downloads .downloads-wrapper .sem-destaque .download-box .opacity-background {
        width: 50%;
    }
    .destaque, .sem-destaque {
        padding: 0px;
    }
    .big-heading {
        h2 {
            font-size: 21px;
            margin: 0px;
            margin-bottom: 30px !important;
            font-weight: 300;
        }
    }
    .btn-video {
        position: absolute;
        top: 26%;
        left: 44%;
    }
    .texto {
        margin-bottom: 30px !important;
    }
    header {
        padding-top: 10px;
        padding-bottom: 3px;
        nav {
            padding-top: 0px;
        }
    }
    .footer {
        nav {
            float: left !important;
            ul li {
                a {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #FFF;
                    &:hover {
                        color:  $orange-contraven;
                    }
                }
            }
        }
        .active {
            a {
                color: $orange-contraven !important;
            }
        }
    }

    .red-bg {
        background-color: $footer-bg;
    }
    .areas-destaques, .areas-pequenas {
        .info-wrapper {
            margin-left: 15px !important;
            margin-top: 10px !important;
        }
    } 

    .areas {
        .col-xs-12 {
            padding: 0px;
        }
        .areas-destaques, .areas-pequenas {
            margin-bottom: 0px;
            li {
                margin-top: 0px; 
                .col-xs-12, .col-xs-6 {
                    padding: 0px;
                    .inner {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

}

@media (max-width : 514px){
    .aceitar-cookies { 
        margin-bottom: 15px;
    }
}

@media (max-width : 500px){
    .grid-sizer,
    .box { width: 50%; }
}


@media (max-width : 530px){
    .small-seta-icon {
        display: inline-block;
        border-radius: 10px;
        background-color: $orange-contraven;
        color: #FFF;
        font-size: 7px !important;
        height: 12px;
        width: 12px;
        text-align: center;
        vertical-align: middle;
        padding-top: 3px;
        padding-left: 1px;
        margin-top: -2px;
    }

    .downloads .downloads-wrapper .sem-destaque .download-box .opacity-background .info-wrapper .title {
        height: 25px;
        max-height: 25px;
    }
    .downloads-wrapper {
        .destaque, .sem-destaque {
            .download-box {
                position: relative;
                .img-wrapper {
                    img {
                        width: 100%;
                    }
                }
                .opacity-background {
                    .info-wrapper {
                        padding: 10px!important;
                        margin: 0px !important;
                        .title {
                            h4 {
                                font-size: 4vw!important;
                            }
                        }
                        .link {
                            i {
                                display: inline-block;
                                border-radius: 10px;
                                background-color: #FFF;
                                color: $orange-contraven;
                                font-size: 7px;
                                height: 10px;
                                width: 10px;
                                text-align: center;
                                vertical-align: middle;
                                padding-top: 1px;
                                padding-left: 1px;
                                margin-top: -2px;
                            }
                            .text {
                                font-size: 2vw!important;
                            }
                        }
                    }
                    &:hover {
                        @include transition;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width : 400px){

    .footer nav ul li a {
        font-size: 2.8vw;
    }

    a.logo {
        svg {
            width: 200px !important;
        }
    }
    .areas {
        .areas-destaques, .areas-pequenas {
            li {
                a {
                    .inner {
                        .opacity-background {
                            .info-wrapper {
                                .title {
                                    font-size: 4vw;
                                }
                                .link {
                                    font-size: 2vw;
                                    .text {
                                        color: $orange-contraven;
                                        margin-left: 3px;
                                    }
                                }
                            }
                            &:hover {
                                .title {
                                    font-size: 5.5vw;
                                }
                                .keywords {
                                    li {
                                        font-size: 5.5vw;
                                    }
                                }
                                .link {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .areas {
        .areas-destaques, .areas-pequenas {
            li a .inner .opacity-background .info-wrapper .link {
                font-size: 3vw !important;
                i {
                    font-size: 6px !important;
                    height: 12px !important;
                    width: 12px !important;
                    padding-top: 3px !important;
                    padding-left: 2px !important;
                    margin-top: 1px !important;
                }
            }
        }
    } 

}

@media (min-width : 491px) and (max-width : 530px){
    .downloads .downloads-wrapper .sem-destaque .download-box .opacity-background .info-wrapper .title {
        height: 44px!important;
        max-height: 44px!important;
    }
    .downloads .downloads-wrapper .destaque .download-box .opacity-background .info-wrapper .title {
        height: 44px!important;
        max-height: 44px!important;
    }
}

@media (min-width : 431px) and (max-width : 490px){
    .downloads .downloads-wrapper .sem-destaque .download-box .opacity-background .info-wrapper .title {
        height: 39px!important;
        max-height: 39px!important;
    }
    .downloads .downloads-wrapper .destaque .download-box .opacity-background .info-wrapper .title {
        height: 39px!important;
        max-height: 39px!important;
    }
}

@media (min-width : 365px) and (max-width : 430px){
    .downloads .downloads-wrapper .sem-destaque .download-box .opacity-background .info-wrapper .title {
        height: 34px!important;
        max-height: 34px!important;
    }
    .downloads .downloads-wrapper .destaque .download-box .opacity-background .info-wrapper .title {
        height: 34px!important;
        max-height: 34px!important;
    }
}

@media (max-width : 364px) {
    .downloads .downloads-wrapper .sem-destaque .download-box .opacity-background .info-wrapper .title {
        height: 27px!important;
        max-height: 27px!important;
    }
    .downloads .downloads-wrapper .destaque .download-box .opacity-background .info-wrapper .title {
        height: 27px!important;
        max-height: 27px!important;
    }
}

@media (max-width : 570px) {
    .left-box {
        left: 82.4% !important;
    }
    .right-box {
        left: 20% !important;
    }
}

@media (max-width : 528px) {
    .aceitar-cookies {
        float: right;
    }
}

@media (max-width : 370px) {
    .footer nav ul li a {
        font-size: 2.5vw;
    }
}


