.big-heading {
    line-height: 30px;
    h2 {
        font-size: 26px;
        font-family: 'Raleway';
        font-weight: lighter;
        color: $orange-contraven;
        line-height: 30px;
        margin: 0px;
        padding-right: 110px;
    }
}

.video-wrapper {
    margin-bottom: 30px;
    a {
        @include transition1;
        &:hover {
            @include transform;
            @include transition1;
        }
    }
    img {
        width: 100%;
    }
}

/* SETAS */

.small-seta-icon {
    border: 0px;
    border-radius: 10px;
    background-color: $orange-contraven;
    color: #FFF;
    font-size: 7px !important;
    height: 14px;
    width: 14px;
    text-align: center;
    vertical-align: top;
    padding-top: 4px;
    padding-left: 2px;
    margin-top: 2px;
}